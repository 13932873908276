.about_box {
    margin-top: 84px;
}

.about1_box {
    position: relative;
}

.about2_box {
    columns: 1;
    background: #FFFFFF;
    color: #000000;
    padding-bottom: 48px !important;
    padding: 48px 120px 0px 120px;
    column-gap: 40px;
    column-fill: balance;
    position: relative;
    font-size: 16px;
    line-height: normal;
}

.description-section-body {
    font-family: 'HearstRegular', sans-serif;
    font-size: 17px;
}

.description-section-body p {
    padding-bottom: 10px;
    margin-bottom: 0px;
    display: table;
    line-height: 21px;
    margin-top: 0;
}

.description-section-body strong {
    font-family: 'HearstBold', sans-serif;

}

.about3_box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 100%;
}

.about3_box_inner {
    width: 25%;
    position: relative;
    min-height: 1px;
    padding: 1px 2px 1px 0px;
    z-index: 9;
    background-color: transparent;
    overflow: hidden;
}

.about3_box_inner img {
    height: 18.87vw !important;
    width: 100%;
    transition: transform 1s;
    object-fit: cover;
    z-index: 1;
    overflow: hidden;
}

.about3_box_inner:hover {
    background-color: #FFFFFF;
    overflow: hidden;
}

.about3_box_inner img:hover {
    transform: scale(1.1);
    opacity: 0.3;
    overflow: hidden;
}

.col-four-text {
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    color: #fff;
    z-index: 9;
    transition: color 1s;
}

.front-card p {
    opacity: 1 !important;
    transition: 0.2s all;
    font-size: 22px;
    margin: 0;
    font-weight: 600;
}

.about3_box_inner:hover .col-four-text {
    color: #000;
}

.about4_box h2 {
    font-size: 2.5rem;
    color: black;
    margin: 3.5rem 5rem 2rem 7.5rem;
    padding: 0;
    font-family: "HearstMedium", sans-serif;
    font-weight: 500;
    line-height: 1.2;
}

.about4_box_flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 100%;
}

.about4_box_inner {
    height: 25.069vw;
    padding: 1px 2px 1px 0px !important;
    width: 33.33333%;
    position: relative;
    z-index: 9;
    overflow: hidden;
}

.about4_box_inner img {
    height: 25.069vw;
    width: 100%;
    transition: transform 1s;
    object-fit: cover;
    z-index: 1;
    overflow: hidden;
}

.brand-inner-card {
    position: absolute;
    left: 40%;
    top: 42%;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.black-main-cutline {
    font-size: 3.35vw !important;
    margin: 0 !important;
    transition: color 0.3s ease;
}



.about4_box_inner:hover {
    background-color: #FFFFFF;
}

.about4_box_inner img:hover {
    transform: scale(1.1);
    opacity: 0.3;

}

.about4_box_inner:hover .black-main-cutline {
    color: #000 !important;
}