.bottom-container {
  width: 100%;
}

.foot_top {
  background-color: #f6f6fa !important;
  margin: 0;
  padding: 0;
}

.foot_top_inner {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  border-top: none;
  margin-bottom: 1.875rem !important;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
}

.foot_top_inner ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.foot_top_inner .featured-link-list li:not(:last-child) {
  margin-bottom: 0;
  margin-right: 1.25rem;
}

.foot_top_inner .featured-link-list li {
  display: inline-block;
  list-style: none;
}

.featured-link {
  text-transform: uppercase;
  letter-spacing: .0625em;
  font-size: 1rem;
  font-family: "Proxima N W01 Smbd";
  color: #039be5;
  text-decoration: none;
  font-weight: 700;
}

.social-link-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  list-style: none;
  list-style-image: none;
}

.social-link-list ul li {
  margin: 0;
  padding: 0;
}

.social-icon-link {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  padding: .625rem;
  color: #039be5;
  line-height: 0;
  text-decoration: none;
}

svg.icon {
  max-width: 413px;
  height: 100%;
  width: auto;
  color: inherit;
  fill: currentColor;
}

.foot_mid {
  justify-content: space-between;
  flex-direction: row;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
}

.foot_mid_left {
  margin: 0 0 3.75rem;
  display: flex;
}

.secondary-nav {
  font-size: .75rem;
  border-top: none;
  text-align: left;
}

.secondary-nav:not(:last-child) {
  margin-right: 3.75rem;
}

.secondary-nav h2 {
  margin-bottom: .625rem !important;
  padding: 0;
  font-family: "Proxima N W01 Smbd";
  text-transform: none;
  letter-spacing: 0;
  position: relative;
  font-size: 1rem;
  line-height: 1.25;
  color: #333337;
  margin: 0;
  font-weight: 600;
}

.secondary-nav ul {
  display: block;
  padding-left: 0;
  padding-right: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.secondary-nav ul li:not(:last-child) {
  margin-bottom: .625rem;
}

.secondary-nav ul li {
  margin: 0;
  padding: 0;
}

.secondary-nav ul li a {
  color: #747477;
  text-decoration: none;
}

.footer-row {
  justify-content: space-between;
  flex-direction: row;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
}

.footer-row a {
  color: #747477;
  display: flex;
  align-items: baseline;
  width: 100%;
  background-color: #f6f6fa;
  padding: .625rem 1.25rem;
  margin-bottom: 1.875rem;
  border-top: none;
  text-decoration: none;
}

.careers>:not(:last-child) {
  margin-right: .625rem;
  margin-bottom: 0;
  margin-top: 0;
}

.careers__heading-2 {
  font-size: 1rem;
  line-height: inherit;
  font-family: "Proxima N W01 Bold";
  color: #333337;
  padding: 0;
  margin: 0;
}

.careers__cta {
  margin: 0 0 0 auto;
}

.featured-link {
  text-transform: uppercase;
  letter-spacing: .0625em;
  font-size: 1rem;
  font-family: "Proxima N W01 Smbd";
  color: #039be5;
  text-decoration: none;
  line-height: inherit;
  padding: 0;
}

.featured-link--arrow::after {
  content: "";
  margin-left: .25em;
  position: relative;
  display: inline-block;
  border-top: 2px solid;
  border-right: 2px solid;
  width: .65em;
  height: .65em;
  transform: rotate(45deg);
  transition: transform .3s;
}

.legal {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-size: 12px;
  line-height: 1.25;
}

.footer-block {
  padding: 0 1.25rem;
  border-top: none;
}

.legal__flex-group {
  border-top: none;
  width: 100%;
  padding: 0;
  margin-bottom: 3.75rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.legal__flex-group>* {
  margin-bottom: 0;
}

.legal__flex-group p {
  margin: 0;
  color: #747477;
}

.legal__flex-group ul {
  margin: 0;
  pad: 0;
  margin-left: auto !important;
  list-style-type: none;
}

.legal__flex-group ul li:not(:last-child) {
  margin-right: 1.25rem;
}

.legal__flex-group ul li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.legal__flex-group ul li a {
  color: #747477;
  text-decoration: none;
}

.foot_bot p {
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 1em !important;
  margin: 0 0 1em;
  color: #747477;
  text-align: center;
}

.foot_bot a {
  color: #747477;
  text-align: center;
}

.foot_bot_bot {
  margin-top: 1.25rem;
  display: block;
}

.foot_bot_bot_img {
  text-align: center;
}

.foot_bot_bot_img img {
  height: 40px;
}

.foot_bot_bot a {
  font-family: "Proxima N W01 Bold" !important;
  color: #333337 !important;
  text-decoration: none;
  margin: auto;
  display: block;
  font-weight: 600;
  margin-bottom: 20px !important;
}