 .header-container {
   max-width: 1680px;
   margin: 0 auto;
 }

 .header {
   padding: 0;
   border-bottom: solid 1px #CCCCCC;
   max-width: 1400px;
   margin: auto;
   z-index: 103;
   position: relative;
   background-color: #F5FBFF;
 }

 .header_body {
   max-width: 1680px;
   margin: 0 auto;
   width: 100%;
 }

 .header_top {
   height: 43px;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   background-color: #f5fbff;
   cursor: pointer;
 }

 .css-4yi0cx {
   margin-right: 3px;
   margin-top: 5px;
 }

 .css-1en8fka {
   color: #00b1eb;
   font-family: "Proxima Nova", sans-serif;
   margin-bottom: 0 !important;
   font-size: 16px;
   line-height: 22px;
   margin: 0 0 1em;
 }

 .css-1vxcvqv {
   transform: rotate(-90deg);
   margin-left: 8px;
   color: #039BE5;
 }

 .header_body_inner {
   padding-left: 24px !important;
   padding-right: 24px !important;
   width: 100%;
   max-width: 1400px;
   margin: 0 auto;
   padding: 0 20px;
 }

 .header_body_internal {
   display: flex;
   align-items: center;
   flex-direction: column;
   width: 100%;
   box-sizing: border-box;
 }

 .css-1526lem {
   width: 100%;
   margin: 0 auto;
   box-sizing: border-box;
   min-height: 154px;
 }

 .css-jnx4tf {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin-top: 10px;
   color: #747477;
   margin-bottom: 10px;
   font-size: 0.75rem;
   font-family: "Proxima Nova", sans-serif;
 }

 .css-ytumd6 {
   text-decoration: none;
 }

 .css-1mcn6si {
   display: flex;
   flex-direction: row;
   align-items: center;
 }

 .css-15h7lbw {
   font-size: 0.75rem;
   line-height: 1.5;
   text-align: right;
   font-family: "Proxima-Light", sans-serif;
   color: #747477;
   cursor: pointer;
 }

 .css-1g11uww {
   font-family: "Proxima-Light", sans-serif;
   height: 8px;
   background: #747477;
   margin: 0 14px;
   width: 0.4px;
 }

 .css-5nuf5g {
   text-decoration: none;
   font-size: 0.75rem;
   text-transform: capitalize;
   line-height: 1.124rem;
   text-align: right;
   font-family: "Proxima-Light", sans-serif;
   color: #747477;
   cursor: pointer;
 }

 .css-1jwzl8m {
   position: relative;
   width: 24px;
   height: 16px;
 }

 img {
   max-width: 100%;
   height: auto;
 }

 .css-1mcn6si {
   display: flex;
   flex-direction: row;
   align-items: center;
 }

 .css-15h7lbw {
   font-size: 0.75rem;
   line-height: 1.5;
   text-align: right;
   font-family: "Proxima-Light", sans-serif;
   color: #747477;
   cursor: pointer;
 }

 .css-1gq8co7 {
   width: 8px;
 }

 .css-69i1ev {
   display: flex;
   justify-content: space-between;
   align-items: center;
 }

 .css-1oizw6e {
   width: 30%;
 }

 .css-1oizw6e a {
   display: block;
 }

 .css-rgii2x {
   width: 51.5%;
   position: relative;
 }

 .css-rgii2x label {
   margin-bottom: 5px;
   display: block;
 }

 .css-13oo6ww {
   border: solid 1px #c4c4cc;
   border-radius: 3px;
   height: 45px;
   display: flex;
   align-items: center;
   box-sizing: border-box;
 }

 .css-uj22jg {
   color: #000000;
   height: auto;
   border-radius: unset;
   width: 100%;
   font-family: "Proxima-Light", sans-serif;
   display: inline-block;
   box-sizing: border-box;
   padding: 10px 16px;
   line-height: 1;
   border: none;
   outline: none;
   text-decoration: none;
   font-size: 1rem;
   box-shadow: none !important;
   z-index: 1;
 }

 .css-tx0cnb {
   padding: 0 16px;
   border: 0;
   cursor: pointer;
   background-color: #FFFFFF;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
 }

 .css-1c2deqw {
   position: relative;
   display: flex;
   justify-content: space-between;
   margin-top: 5px;
   min-height: 60px;
 }

 .head_nav_left {
   display: flex;
   flex-grow: 1;
   align-items: center;
 }

 .head_nav_left_inner {
   position: relative;
   display: block;
 }

 .head_nav_left_inner button {
   margin-right: 22px;
   cursor: default;
   border-bottom: solid 4px rgba(255, 255, 255, 0);
   font-size: 14px;
   line-height: 22px;
   color: #333337;
   font-family: 'Proxima-Regular', sans-serif;
   padding-top: 20px;
   padding-bottom: 16px;
   letter-spacing: 0.3px;
   border: none;
   background: transparent;
   white-space: wrap;
 }

 .head_nav_left_inner a {
   text-decoration: none;
   color: #333337;
 }

 .head_nav_right {
   display: flex;
   align-items: center;
   cursor: pointer;
   margin-left: 20px;
   justify-content: end;
 }

 .head_nav_right a {
   display: flex;
   padding: 0;
   position: relative;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   color: #005dab;
 }

 .css-y79iqg {
   position: relative;
   display: inline-flex;
   vertical-align: middle;
   flex-shrink: 0;
   margin-right: 2px;
 }

 .head_nav_right a svg {
   height: 24px;
   width: 30px;
 }

 .MuiBadge-badge {
   text-align: center;
   height: 17px;
   min-width: 16px;
   font-size: 0.687rem;
   font-family: 'Proxima-SemiBold', sans-serif;
   color: #FFFFFF;
   padding: 0 4px;
   background-color: #039BE5;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
   position: absolute;
   box-sizing: border-box;
   font-weight: 500;
   line-height: 1;
   border-radius: 10px;
   z-index: 1;
   transition: transform 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   top: -5px;
   right: -4px;
   transform-origin: 100% 0%;
 }